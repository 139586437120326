import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import classNames from "classnames"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import componentStyles from "./sitemap.module.scss"
import { useBlogPosts } from "../hooks"

const restrictedKeyWords = ["pubmatic", "404", "sitemap"]

const Sitemap: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const componentPages = useStaticQuery(
    graphql`
      query SitemapComponentPages {
        allSitePage(
          filter: { context: { type: { eq: null } } }
          sort: { fields: path }
        ) {
          edges {
            node {
              path
              context {
                externalLink
                language
              }
            }
          }
        }
      }
    `
  )
  const filteredComponentPages = componentPages?.allSitePage?.edges
    .filter(({ node }) => {
      const { path } = node
      // exclude main page
      if (path === "/" || path === `/${intl.locale}/`) {
        return false
      }
      // exclude pages with some keywords in path
      if (restrictedKeyWords.some(value => path.includes(value))) {
        return false
      }
      // exclude non current language pages
      return node?.context?.language === intl.locale
    })
    .map(({ node }) => {
      const { path } = node
      const splittedPath = path
        .split("/")
        .filter(part => part)
        .slice(1)
        .join(".")
      const title = intl.formatMessage({
        id: `${splittedPath}.seo.title`,
      })
      return {
        title,
        path,
      }
    })
  const markdownPages = useBlogPosts(intl.locale)
  const filteredMarkdownPages = markdownPages?.edges
    .filter(({ node }) => {
      // exclude blog posts on external resources
      return !node?.frontmatter?.externalLink
    })
    .map(({ node }) => {
      return {
        title: node?.frontmatter?.title,
        path: node?.fields?.slug,
      }
    })
  const resultPages = filteredComponentPages
    .concat(filteredMarkdownPages)
    .sort(({ path: path1 }, { path: path2 }) => {
      path2 > path1 ? 1 : 0
    })

  const containerClasses = classNames(componentStyles.container, "container")

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({ id: "sitemap.seo.description" })}
        title={intl.formatMessage({ id: "sitemap.seo.title" })}
        pathname={location.pathname}
      />
      <section className={containerClasses}>
        <h1 className="large-section-title">
          {intl.formatMessage({ id: "sitemap.title" })}
        </h1>
        <ul className={componentStyles.linkList}>
          {resultPages.map(({ path, title }) => (
            <li key={path}>
              <a href={path}>{title}</a>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  )
}

export default Sitemap
